.link {
  text-decoration: underline;
  cursor: pointer;
}

.text {
  word-break: break-all;
  overflow: hidden;
}

.popoverChildren {
  width: 100%;
}

.tooltipFull {
  width: 100%;
}

.dark {
  color: var(--white);
}

.light {
  color: var(--black);
}