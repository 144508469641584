.description {
  font-weight: 600;
  margin-bottom: 5px;
}

.tooltip {
  color: var(--black);
  padding: 15px 15px 28px 15px;
  max-width: 332px;
}
